html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto; /* Ensure the whole page scrolls */
}

.iframe-container, .map-container {
  width: 100%;
  max-width: 900px; /* Set a max-width for consistent layout */
  height: 100vh; /* Make each iframe full-screen height */
  overflow: hidden;
  margin: 0 auto; /* Center the container */
}

.iframe-container iframe, .map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contact-info {
  margin: 20px 0; /* Add some spacing above and below the contact info */
}

@media (max-width: 768px) {
  .iframe-container, .map-container {
    height: auto; /* Adjust iframe height for smaller screens */
  }

  .iframe-container iframe, .map-container iframe {
    height: 600px; /* Set a default height for iframes on mobile */
  }
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .profile-pic-placeholder img {
    width: 250px; /* Adjust size as needed */
    height: 250px; /* Adjust height for a rectangular shape */
    border-radius: 10px; /* Optional: You can adjust this to create rounded corners or remove it for sharp corners */
    margin-bottom: 20px;
  }
  
  .content {
    display: flex;
    justify-content: space-around;
    text-align: left;
    flex-wrap: wrap; /* Ensures responsiveness */
  }
  
  .paragraph {
    flex: 1;
    margin: 0 10px;
    min-width: 250px; /* Prevents the paragraphs from becoming too narrow */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
      align-items: center;
    }
  
    .paragraph {
      /* Ensures paragraphs stack vertically on narrower screens */
      margin-bottom: 20px;
    }
  }
  
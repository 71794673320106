.reviews-container {
  padding: 20px;
  text-align: center;
  font-family: Calibri, Arial, sans-serif; /* Apply Calibri font, with fallback to Arial */
  font-size:1.2rem;
}

.reviews-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.review-placeholder {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 150px;
  height: auto;
  border: 3px solid gray;
  font-size: 16px; /* Adjusted font size for readability */
  line-height: 1.5; /* Improved readability with a good line height */
  font-family: Calibri, Arial, sans-serif; /* Ensure Calibri is applied to this section */
}

.review-placeholder p {
  margin-bottom: 10px;
}

.review-placeholder span {
  margin-top: 5px;
  font-weight: bold; /* Make client name bold */
}

/* Mobile view */
@media (max-width: 768px) {
  .reviews-grid {
    grid-template-columns: 1fr;
  }

  .review-placeholder {
    font-size: 12px; /* Smaller font size for mobile */
  }
}

/* SignDocuments.css */

.sign-documents {
  padding: 20px;
  text-align: center; /* Center align content for better visibility */
}

.signatureCanvas {
  border: 1px solid #ccc;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

label input,
label .react-datepicker-wrapper {
  display: block;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.modal {
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pdf-viewer-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #f0f0f0; /* Light background for better contrast */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-viewer-container iframe,
.pdf-viewer-container .react-pdf__Document {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  color: #333;
}

@media (max-width: 768px) {
  .pdf-viewer-container {
    height: calc(100vh - 20px);
    padding-top: 0;
  }

  .pdf-viewer-container iframe {
    transform: scale(0.9); /* Slightly larger scale for better readability on mobile */
    width: 111%; /* Adjust width for scaling */
    height: 111%; /* Adjust height for scaling */
    transform-origin: top left; /* Ensure scaling aligns with the top left */
  }

  .sign-documents {
    padding: 10px;
  }

  button[type="submit"] {
    font-size: 14px;
    padding: 10px;
  }

  label {
    font-size: 14px;
  }

  body, html {
    overflow-x: hidden;
  }
}
/* SignDocuments.css */

.button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px; /* Adds space between buttons */
}

.button-group .btn-custom {
  background-color: #28a745; /* Bootstrap's green color */
  color: white;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  width: 48%;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.button-group .btn-custom:hover {
  background-color: #218838; /* Darker green on hover */
  color: #f8f9fa; /* Light color on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.button-group .btn-custom:active {
  background-color: #1e7e34; /* Even darker green when active */
  transform: translateY(2px); /* Pressed down effect */
}

@media (max-width: 768px) {
  .button-group {
      flex-direction: column; /* Stack buttons vertically on mobile */
  }

  .button-group .btn-custom {
      width: 100%; /* Full width on mobile */
      margin-bottom: 10px; /* Space between stacked buttons */
  }
}

/* App.css */
.App {
  text-align: center;
}

/* Other styles remain unchanged */

/* Adjusted media query to avoid affecting .dark-pink-navbar */
@media (max-width: 768px) {
  /* If you need to adjust styles within .dark-pink-navbar for responsiveness, do it here */
  .dark-pink-navbar .navbar {
    /* Example: Adjust navbar layout for mobile */
    flex-direction: column;
  }
}

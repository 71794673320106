.dark-pink-navbar {
  padding: 0 !important;
  background-color: #6F7E9B !important;
  border-color: #6F7E9B !important;
  position: sticky !important; /* Ensure sticky is applied */
  top: 0;
  z-index: 1020;
  width: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

.dark-pink-navbar .navbar-brand {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.dark-pink-navbar .navbar-brand img {
  max-height: 70px;
  width: auto;
  margin: 0 auto;
}

.dark-pink-navbar .navbar-toggler {
  padding: 0.25rem 0.5rem;
  border: none;
  background-color: transparent;
  outline: none;
}

.dark-pink-navbar .navbar-toggler .close-icon {
  font-size: 1.5rem;
  color: white;
}

.dark-pink-navbar .navbar-toggler-icon {
  background-image: none;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  position: relative;
}

.dark-pink-navbar .navbar-toggler.collapsed .navbar-toggler-icon::before,
.dark-pink-navbar .navbar-toggler.collapsed .navbar-toggler-icon::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: white;
  left: 0;
  transition: transform 0.2s ease-in-out;
}

.dark-pink-navbar .navbar-toggler.collapsed .navbar-toggler-icon::before {
  top: 0;
  transform: translateY(6px);
}

.dark-pink-navbar .navbar-toggler.collapsed .navbar-toggler-icon::after {
  bottom: 0;
  transform: translateY(-6px);
}

.dark-pink-navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
  transform: rotate(45deg);
  top: 50%;
}

.dark-pink-navbar .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
  transform: rotate(-45deg);
  bottom: 50%;
}

.dark-pink-navbar .nav-link {
  color: white;
  padding: 0 10px;
  line-height: 70px;
}

@media (max-width: 992px) {
  .dark-pink-navbar .navbar-brand img {
    max-height: 50px;
  }
}

@media (max-width: 768px) {
  .dark-pink-navbar {
    justify-content: space-between;
  }

  .dark-pink-navbar .container, 
  .dark-pink-navbar .container-fluid, 
  .dark-pink-navbar .container-sm, 
  .dark-pink-navbar .container-md, 
  .dark-pink-navbar .container-lg, 
  .dark-pink-navbar .container-xl, 
  .dark-pink-navbar .container-xxl {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .dark-pink-navbar .navbar-brand {
    order: 0;
    flex-grow: 1;
    justify-content: center;
    display: flex;
  }

  .dark-pink-navbar .navbar-toggler {
    order: 1;
    flex-grow: 0;
    align-self: center;
  }

  .dark-pink-navbar .navbar-collapse {
    order: 2;
    flex-basis: 100%;
    text-align: center;
  }

  .dark-pink-navbar .navbar-nav {
    justify-content: center;
    width: 100%;
    flex-direction: column; /* Stacks nav-links vertically */
  }

  .dark-pink-navbar .nav-link {
    padding: 0.5rem 1rem; /* Adjusted padding */
    line-height: normal; /* Adjusted line-height */
  }

  .dark-pink-navbar .navbar-toggler.collapsed .navbar-toggler-icon::before {
    transform: translateY(6px) rotate(0);
  }

  .dark-pink-navbar .navbar-toggler.collapsed .navbar-toggler-icon::after {
    transform: translateY(-6px) rotate(0);
  }
}

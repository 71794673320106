/* Base styles */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.banner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
}

/* Assuming your desktop banner is set directly in the img tag */
/* If you're switching to background images for responsive purposes, you'll need to set the desktop image here as well */

.banner img {
  display: block; /* Ensure img is used when specific background images are not set */
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

@media (min-width: 769px) {
  .banner {
    height: 80vh;
  }
}

@media (max-width: 768px) {
  .banner {
    background-image: url('../assets/img/banner-mobile_final.png'); /* Mobile-specific banner image */
    height: 35vh; /* Adjusted height for mobile devices */
  }
  .banner img {
    display: none; /* Hide the img tag on mobile if using background-image */
  }
}

.contact-info {
  margin: 20px;
}

.content-row-text {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.content-block-text {
  flex: 1;
  min-width: 250px;
  max-width: 33%;
  text-align: left;
}
/* Adjustments for mobile screen views */
@media (max-width: 768px) {
  .content-block-text {
    padding: 0 10px; /* Reduced side padding on mobile for more text space */
    max-width: 100%; /* Allows text block to fill available width */
  }
}

@media (max-width: 480px) {
  .content-block-text {
    padding: 0 5px; /* Further reduce padding for smaller screens */
  }
}

@media (min-width: 769px) {
  .product-block, .useful-items {
    max-width: calc(33.333% - 40px);
  }
}

.home .map-container {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.home .map-container iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

@media (max-width: 768px) {
  .content-row {
    flex-direction: column;
  }
  
  .product-block, .useful-items, .map-container {
    max-width: 100%;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home .map-container iframe {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .product-block, .useful-items, .map-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .home .map-container iframe {
    height: 200px;
  }
}

.useful-item h4 {
  display: inline-block;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px auto;
  text-align: center;
}

.product-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

.product-block {
  flex: 1 1 calc(25% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 250px;
  max-width: calc(25% - 20px);
}

.product-block img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border: 2px solid #6F7E9B;
}

.item-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .product-row {
    justify-content: center;
  }

  .product-block {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.product-block a {
  text-decoration: none;
}

.disclaimer {
  background-color: #f8f9fa;
  color: #333;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 0.9rem;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.disclaimer p {
  margin: 0;
}

.reviews-section {
  background-color: #333; /* Dark background for contrast */
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 900px;
  margin: 20px auto; /* Center the reviews section */
  position: relative;
  overflow: hidden;
  height: 270px; /* Height for larger screens */
}

.review-box {
  padding: 30px;
  border: 3px solid #000; /* 3px thick black border */
  border-radius: 12px;
  background-color: #6F7E9B !important; /* Gray background color */
  color: #fff;
  font-size: 1.2em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(100%); /* Start off-screen to the right */
  transition: transform 0.8s ease-in-out;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Position the link at the bottom */
  height: 100%; /* Ensure the box fills the height of the container */
}

.review-box.show {
  transform: translateX(0); /* Slide into view */
}

.review-box.hide {
  transform: translateX(-100%); /* Slide out of view */
}

.review-box p {
  font-style: italic;
  margin-bottom: 15px;
}

.review-box span {
  font-weight: bold;
  margin-top: 10px;
}

.review-link {
  text-align: center;
  margin-top: auto; /* Push the link to the bottom */
  margin-bottom: 10px; /* Add some space from the bottom */
}

.review-link a {
  color: #fff;
  text-decoration: underline;
}

.review-link a:hover {
  text-decoration: none;
  color: #ffd700; /* Hover effect with a gold color */
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .reviews-section {
    height: 315px; /* Further increased height for mobile screens */
  }

  .review-box {
    padding: 20px; /* Adjust padding for mobile */
    height: 100%; /* Ensure the box fills the height of the container */
  }
}

/* InputPage.css */

.input-page {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto; /* Let the content adjust height as needed */
}

.client-page {
  margin-top: 5mm; /* Adjust the form content downward by 30mm for the client */
}

.input-page form {
  width: 50%;
  max-width: 600px;
  margin: 0 auto;
}

.input-page label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold; /* Ensure labels are visible and distinguishable */
}

.input-page input,
.input-page select,
.input-page .signatureCanvas,
.input-page .react-datepicker-wrapper {
  display: block;
  width: 100%; /* Ensure all fields take up the full width of the form */
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-page button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Ensure the button also takes up the full width */
}

.input-page button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .input-page form {
    width: 100%; /* Full width on mobile */
  }

  .input-page label,
  .input-page input,
  .input-page select,
  .input-page .signatureCanvas,
  .input-page .react-datepicker-wrapper,
  .input-page button {
    width: 100%; /* Full width for all elements on mobile */
  }
}

.custom-datepicker {
  background-color: #ffffff; /* Ensure solid white background */
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it is above other elements */
}

.custom-datepicker .react-datepicker__header {
  background-color: #ffffff; /* Solid white background for the header */
  border-bottom: 1px solid #ccc;
}

.custom-datepicker .react-datepicker__day,
.custom-datepicker .react-datepicker__day-name {
  background-color: #ffffff; /* Solid background for day and day names */
  color: #333; /* Text color to ensure readability */
}

.custom-datepicker .react-datepicker__current-month {
  background-color: #ffffff; /* Solid background for the current month */
  color: #333; /* Text color to ensure readability */
}
